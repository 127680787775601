import {Injectable, Inject} from '@angular/core';
import {Action, Actions, ofAction, Selector, State, Store, createSelector} from '@ngxs/store';
import {concat, forkJoin, of, throwError, from} from 'rxjs';
import {catchError, concatMap, delay, mergeMap, takeUntil, tap, map, finalize} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';
import {RecordPage, Sider} from './app.actions';
import {UserService} from '../services/user.service';
import {LocalService} from '../services/local.service';
import {RecordService} from '../services/record.service';
import {EntityService} from '../services/entity.service';
import {ActionService} from '../services/action.service';
import {CommentService} from '../services/comment.service';
import {CheckpointService} from '../services/checkpoint.service';
import {TagService} from "../services/tag.service";
import {AttachmentService} from '../services/attachment.service';
import {ActivityService} from '../services/activity.service';
import {CheckpointAttachmentsService} from '../services/checkpoint-attachments.service';
import {Router} from '@angular/router';
import {
  checklistObjects,
  locationToQueryParams,
  mutateState,
  groupRecords,
  computePageSize,
  openDetailPage
} from '../app.utils';
import {CheckpointsetService} from '../services/checkpointset.service';
import {Location} from '@angular/common';
import {ChoiceService} from '../services/choice.service';
import {Cache} from "../classes/cache";

export const extensions = ['Attachments', 'Discussion', 'Checklist', 'History', 'Audit'];
export const fields = ['SingleSelect', 'MultiSelect', 'User', 'CreatedBy', 'Users', 'OneToOne', 'InverseOneToOne', 'OneToMany', 'Spaces', 'Space', 'E-Signature'];
export const fieldMany = ['InverseOneToMany', 'ManyToMany', 'InverseManyToMany'];
export const widgets = ['line', 'bar', 'radar', 'doughnut', 'pie', 'polarArea', 'bubble', 'scatter', 'metric', 'table', 'gauge', 'map', 'communication'];

export const defaults = () => {
  return {
    user: null,
    entity: null,
    record: null,
    comments: [],
    activities: [],
    attachments: [],
    checkpointTags: [],
    checkpointsets: [],
    checkpointComments: {},
    checkpoints: {},
    many: {},
    filters: {},
    isReady: false,
    isTabReady: true,
    isCreating: {
      type: '',
      value: false,
      fields: []
    },
    isCreatingFixing: {
      checkpoint: '',
      value: false
    },
    isUpdatingChecklist: {
      checklist: '',
      value: false
    },
    tab: null,
    selectedTabs: {},
    commandResult: {}
  }
}

@State({
  name: 'recordPage',
  defaults: {
    records: {},
    getRecord(id) {
      return this.records[id] ? this.records[id] : defaults();
    },
    initRecord(id) {
      this.records[id] = defaults();
    },
    destroyRecord(id = null) {
      delete this.records[id];
    },
    isRecord(id: number) {
      return this.records[id]?.record?.id == +id;
    }
  }
})
@Injectable()
export class RecordPageState {

  constructor(private userService: UserService,
              private localService: LocalService,
              private recordService: RecordService,
              private entityService: EntityService,
              private actionService: ActionService,
              private commentService: CommentService,
              private checkpointService: CheckpointService,
              private attachmentService: AttachmentService,
              private activityService: ActivityService,
              private checkpointAttachmentsService: CheckpointAttachmentsService,
              private checkpointsetService: CheckpointsetService,
              private choiceService: ChoiceService,
              private store: Store,
              private actions$: Actions,
              private location: Location,
              private toastr: ToastrService,
              private tagService: TagService,
              private router: Router,
              @Inject('Cache') private cache: Cache) {
  }


  @Selector()
  static tabsCount(params: any) {
    return createSelector([RecordPageState], (state: RecordPageState) => {
      const counts = {}
      const instance = state['app'].recordPage.getRecord(params);
      if (instance) {
        const tabbedBlocks = instance.entity.blocks.filter(b => b.isTabbed || b.isSider);
        if (tabbedBlocks && tabbedBlocks.length) {
          for (const block of tabbedBlocks) {
            const elems = _.concat(block.fields, block.extensions, block.widgets);
            const tabbedFields = _.filter(block.fields, (f) => !fieldMany.includes(f.type));
            if (elems.length !== 1 && tabbedFields.length !== elems.length) {
              continue;
            }
            const elem = _.head(elems);
            let count = 0;
            const type = elem.type.toLowerCase();
            if (type === 'checklist') {
              count = _.flatten(_.map(instance.record.originalCheckpointsets, r => r.checkpoints)).filter(c => c).length;
            } else if (type === 'audit') {
              count = _.flatten(_.values(instance.checkpoints)).length;
            } else if (type === 'manytomany' || type === 'inverseonetomany' || type === 'inversemanytomany') {
              count = instance.many[elem.id] ? instance.many[elem.id].mode === 'cards' ? instance.many[elem.id].count : instance.many[elem.id].timelineCount : count;
            } else if (type === 'attachments') {
              count = instance.record.attachments;
            } else if (type === 'discussion') {
              count = instance.record.comments;
            } else if (type === 'history') {
              count = instance.record.activities;
            }
            counts[block.id] = count;
          }
        }
      }
      return counts;
    });
  }

  @Action(RecordPage.Init, {cancelUncompleted: true})
  init(ctx, {recordId, tabId, ready}) {
    if (!ready && !ctx.getState().isRecord(recordId)) {
      mutateState(ctx, draft => {
        draft.initRecord(recordId);
      });
    }
    const record$ = this.recordService.retrieveObject(recordId, ['entity']);
    const user$ = this.userService.retrieveCurrentUser(this.localService.getUser());
    return forkJoin([record$, user$]).pipe(
      mergeMap(([record, user]: any) => {
        const activeSpace = this.localService.getSpace();
        if ((record.space !== activeSpace) && !record.entity.sharedSpaces.includes(activeSpace) && user.spaces.includes(activeSpace)) {
          return this.store.dispatch(new Sider.SwitchSpace(record.space)).pipe(tap(() => {
            this.toastr.info('Changement automatique de l\'espace');
            this.store.dispatch(new RecordPage.Init(recordId, tabId));
          }));
        }
        const entity$ = this.entityService.retrieveMetadata(record.entity.id);
        const record$ = this.recordService.retrieveObject(recordId, ['value', 'tags', 'fixedCheckpoint.checkpointset', 'originalCheckpointsets.checkpoints.[checkpointAttachments,tags,choices]']);
        return forkJoin([of(user), entity$, record$]);
      }),
      mergeMap(([user, entity, record]: any) => {
        entity.blocks = _.map(entity.blocks, b => ({
          ...b,
          widgets: _.filter(b.widgets, w => user.extra.accessibleWidgets.includes(w.id)),
        }));
        entity.blocks = _.sortBy(
          _.filter(entity.blocks, (b) => _.concat(b.fields, b.widgets, b.extensions, b.actions).length > 0),
          b => b.position);
        return forkJoin([of(record), of(entity), of(user)]);
      }),
      mergeMap(([record, entity, user]: any) => {
        mutateState(ctx, draft => {
          if (draft.getRecord(recordId).record?.id === +recordId) {
            draft.getRecord(recordId).record = RecordPageState.loadPrevState(recordId, ctx, record);
          } else {
            draft.getRecord(recordId).record = record;
            draft.getRecord(recordId).user = user;
          }
          if (draft.getRecord(recordId).entity?.id !== entity.id) {
            draft.getRecord(recordId).entity = entity;
          }
          const tabbedBlocks = _.filter(entity.blocks, (b) => _.concat(
            _.filter(b.fields, f => record.fields.find(rf => rf.id === f.id)?.isActiveDetail),
            b.extensions, b.widgets).length > 0).filter(b => b.isTabbed)
          if (tabbedBlocks.length) {
            draft.getRecord(recordId).tab = tabId && _.filter(tabbedBlocks, b => b.title === tabId && b.isActive).length ? tabId : _.head(_.filter(tabbedBlocks, b => !b.block && b.isActive))?.title;
          }
          draft.getRecord(recordId).isReady = true;
        });
        if (ready) {
          return this.store.dispatch(new RecordPage.RefreshBlocks(recordId, ctx.getState().getRecord(recordId).tab));
        }
        return this.store.dispatch([
          new RecordPage.initManyFields(recordId),
          new RecordPage.InitBlocks(recordId, ctx.getState().getRecord(recordId).tab, false)]);
      }),
    );
  }

  @Action(RecordPage.initManyFields, {cancelUncompleted: true})
  initManyFields(ctx, {recordId}) {
    const recordState = ctx.getState().getRecord(recordId);
    const manyFields = _.chain(recordState.entity.blocks)
      .map('fields')
      .flatten()
      .filter(field => _.includes(fieldMany, field.type))
      .value();
    const observables$ = _.map(manyFields, (field, index) => {
      const mirrorField = field.mirrorFields[0];
      const mirrorEntityId = mirrorField.entity?.id || mirrorField.entity;
      const entitySaves = recordState.user.saves.record[mirrorEntityId];
      const mode = entitySaves?.mode || 'cards';
      if (ctx.getState().getRecord(recordId).many[field.id]) {
        if (mode !== ctx.getState().getRecord(recordId).many[field.id].mode) {
          mutateState(ctx, draft => {
            draft.getRecord(recordId).many[field.id].mode = mode;
          });
        }
        return of();
      }

      const queryParams = {
        entity: mirrorEntityId,
        origin_field: mirrorField.name,
        page_size: 0,
        cursor: -1,
        mode: mode,
        [mirrorField.name]: recordId,
        ...field.filter
      }
      const clonedField = _.cloneDeep(field);
      const entity$ = this.entityService.retrieveMetadataWithMirrors(mirrorEntityId);
      const records$ = this.recordService.retrieveObjects(queryParams);
      return forkJoin([entity$, records$]).pipe(
        mergeMap(([entity, records]: any) => {
          if (!ctx.getState().isRecord(recordId)) return of(null);
          clonedField.mirrorFields[0] = _.assign({}, clonedField.mirrorFields[0], {entity});
          manyFields.splice(index, 1, clonedField);
          mutateState(ctx, draft => {
            const record = draft.getRecord(recordId);
            record.entity.blocks = _.map(record.entity.blocks, block => _.assign({}, block, {
                fields: _.map(
                  block.fields, f => f.id === clonedField.id ? _.assign({}, f, {mirrorFields: clonedField.mirrorFields}) : f)
              })
            );
            record.filters[field.id] = field.filter;
            record.many[field.id] = {
              mode,
              records: [],
              count: records.count,
              timelineRecords: [],
              timelineCount: records.count,
              nextCursor: null,
              loading: true,
              loadingTimeline: true
            };
          });
          return of(entity);
        })
      );
    });

    return forkJoin(observables$).pipe(mergeMap(() => {
      const activeManyBlocks$= [];
      const currentRecord = ctx.getState().getRecord(recordId);
      const activeTabbedBlock = this.getActiveTabbedBlock(recordId, ctx, currentRecord?.tab);
      const activeRelations = _.filter(manyFields, relation => relation.block === activeTabbedBlock.id || !relation.isTabbed);
      for (const activeRelation of activeRelations) {
        activeManyBlocks$.push(this.initMfTab(recordId, ctx, false, -1, false, activeRelation));
      }
      return activeManyBlocks$.length ? forkJoin(activeManyBlocks$) : of([]);
    }));
  }

  @Action(RecordPage.InitBlocks, {cancelUncompleted: true})
  initBlocks(ctx, {recordId, tab, isUpdate}) {
    if (!ctx.getState().isRecord(recordId)) return of();
    mutateState(ctx, draft => {
      draft.getRecord(recordId).tab = tab;
    });
    const isSelectedTabsFirstInit = _.isEmpty(ctx.getState().getRecord(recordId).selectedTabs)
    this.setSelectedTabbedBlocks(ctx, recordId, tab);

    const elements$ = [];
    const elements = this.getBlocksElements(recordId, ctx)
    const activeTabbedBlock = this.getActiveTabbedBlock(recordId, ctx, tab);
    for (const elem of elements) {
      if (isSelectedTabsFirstInit || elem.block === activeTabbedBlock.id || elem.title === tab || elem.type) {
        elements$.push(this.initTabMapping(recordId, elem.type, ctx, isUpdate, false, elem));
      }
    }

    if (activeTabbedBlock) {
      elements$.push(this.initTabMapping(recordId, 'fields', ctx, isUpdate, false, activeTabbedBlock));
    }

    if (isSelectedTabsFirstInit) {
      const selectedTabbedBlocks = _.filter(this.getSelectedTabbedBlocks(ctx, recordId), b => b.title !== activeTabbedBlock.title);
      _.forEach(selectedTabbedBlocks, selectedBlock => {
        elements$.push(this.initTabMapping(recordId, 'fields', ctx, isUpdate, false, selectedBlock));
      });
    }
    return elements$.length ? forkJoin(elements$).pipe(!isUpdate ? delay(0) : tap(), tap(() => {
      mutateState(ctx, draft => {
        draft.getRecord(recordId).isTabReady = true;
      });
    })) : of([]);
  }

  @Action(RecordPage.RefreshBlocks, {cancelUncompleted: true})
  refreshBlocks(ctx, {recordId, tab}) {
    if (!ctx.getState().isRecord(recordId)) return of();
    const isSelectedTabsFirstInit = _.isEmpty(ctx.getState().getRecord(recordId).selectedTabs)
    this.setSelectedTabbedBlocks(ctx, recordId, tab);

    const elements$ = [];
    const elements = this.getBlocksElements(recordId, ctx);
    const activeTabbedBlock = this.getActiveTabbedBlock(recordId, ctx, tab);
    for (const elem of elements) {
      if (isSelectedTabsFirstInit || elem.block === activeTabbedBlock.id || elem.title === tab || elem.type) {
        elements$.push(this.initTabMapping(recordId, elem.type, ctx, false, true, elem));
      }
    }

    if (activeTabbedBlock) {
      elements$.push(this.initTabMapping(recordId, 'fields', ctx, false, true, activeTabbedBlock));
    }

    if (isSelectedTabsFirstInit) {
      const selectedTabbedBlocks = _.filter(this.getSelectedTabbedBlocks(ctx, recordId), b => b.title !== activeTabbedBlock.title);
      _.forEach(selectedTabbedBlocks, selectedBlock => {
        elements$.push(this.initTabMapping(recordId, 'fields', ctx, false, true, selectedBlock));
      });
    }
    return elements$.length ? forkJoin(elements$).pipe(delay(0), tap(() => {
      mutateState(ctx, draft => {
        draft.getRecord(recordId).isTabReady = true;
      });
    })) : of();
  }

  @Action(RecordPage.Destroy)
  destroy(ctx, {recordId}) {
    mutateState(ctx, draft => {
      draft.destroyRecord(recordId);
    });
  }

  @Action(RecordPage.UpdateField)
  updateField(ctx, {recordId, field, fieldValue}) {
    const value = {};
    value[field.id] = fieldValue;
    return this.recordService.updateObject({
      id: recordId, value
    }, ['value', 'tags', 'fixedCheckpoint.checkpointset']).pipe(
      mergeMap((record: any) => {
        if (ctx.getState().isRecord(recordId)) {
          mutateState(ctx, draft => {
            draft.getRecord(recordId).record = RecordPageState.loadPrevState(recordId, ctx, record);
          });
          return this.store.dispatch(new RecordPage.InitBlocks(recordId, ctx.getState().getRecord(recordId).tab, true));
        }
      }),
      catchError(err => {
        if (!ctx.getState().isRecord(recordId)) {
          return of(err);
        } else return throwError(err)
      })
    );
  }

  @Action(RecordPage.UpdateCheckboxField, {cancelUncompleted: true})
  updateCheckboxField(ctx, {recordId, fieldId, fieldValue}) {
    const value = {};
    value[fieldId] = fieldValue;
    return this.recordService.updateObject({
      id: recordId, value
    }, ['value', 'tags', 'fixedCheckpoint.checkpointset']).pipe(
      mergeMap((record: any) => {
        if (ctx.getState().isRecord(recordId)) {
          mutateState(ctx, draft => {
            draft.getRecord(recordId).record = RecordPageState.loadPrevState(recordId, ctx, record);
          });
          return this.store.dispatch(new RecordPage.InitBlocks(recordId, ctx.getState().getRecord(recordId).tab, true));
        }
      }),
      catchError(err => {
        if (!ctx.getState().isRecord(recordId)) {
          return of(err);
        } else return throwError(err)
      })
    );
  }

  @Action(RecordPage.ExecuteAction)
  executeAction(ctx, {recordId, actionId, inp, args}) {
    mutateState(ctx, draft => {
      draft.getRecord(recordId).isCreating.type = 'action';
      draft.getRecord(recordId).isCreating.value = true;
    });
    const action$ = this.actionService.createObject({
      action: actionId,
      record: recordId,
      inp,
      args
    }, ['tags', 'fixedCheckpoint.checkpointset']);
    const record$ = this.recordService.retrieveObject(recordId, ['value', 'tags', 'fixedCheckpoint.checkpointset']);
    return action$.pipe(
      tap((a) => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).commandResult = a['commandResult'];
        });
      }),
      mergeMap((a: any) => record$),
      catchError((err) => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).isCreating.value = false;
        });
        //If the error is related to the record itself instead of the action's result
        if (err.url && err.url.includes("/records/")) {
          return of();
        }
        return throwError(err);
      }),
      mergeMap((r: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record = RecordPageState.loadPrevState(recordId, ctx, r);
          draft.getRecord(recordId).isCreating.value = false;
        });
        return this.store.dispatch(new RecordPage.InitBlocks(recordId, ctx.getState().getRecord(recordId).tab, false));
      }),
      catchError(err => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).isCreating.value = false;
        });
        return throwError(err);
      })
    );
  }

  @Action(RecordPage.DestroyRecord)
  destroyRecord(ctx, {recordId}) {
    return this.recordService.destroyObject(recordId);
  }

  @Action(RecordPage.AddRecord)
  addRecord(ctx, {recordId, childRecord, field}) {
    const mirrorEntity = field.mirrorFields[0].entity;
    const newValue = {};
    newValue[field.id] = childRecord.id;
    return this.recordService.updateObject({
      id: recordId,
      value: newValue
    }, ['value', 'tags', 'fixedCheckpoint.checkpointset']).pipe(
      mergeMap(() => {
        return this.recordService.retrieveObject(childRecord.id);
      }),
      mergeMap((r: any) => {
        const fieldIds = _.filter(_.flatten(_.map(mirrorEntity.blocks, (b) => b.fields)), f => f.isExpanded).map(f => f.id).join(',')
        const record$ = this.recordService.retrieveObject(recordId, ['value', 'tags', 'fixedCheckpoint.checkpointset']);
        const childRecord$ = this.recordService.retrieveObject(childRecord.id, ['value.' + fieldIds, 'tags']);
        return forkJoin([record$, childRecord$])
      }),
      tap(([record, childRecord]) => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record = record;
          draft.getRecord(recordId).many[field.id].records.unshift(childRecord);
          draft.getRecord(recordId).many[field.id].count++;
          if (mirrorEntity.startingDateField
            && mirrorEntity.endingDateField
            && childRecord.value[mirrorEntity.startingDateField.id]
            && childRecord.value[mirrorEntity.endingDateField.id]) {
            draft.getRecord(recordId).many[field.id].timelineRecords.unshift(childRecord);
            draft.getRecord(recordId).many[field.id].timelineCount++;
          }
        });
      })
    );
  }

  @Action(RecordPage.RemoveRecord)
  removeRecord(ctx, {recordId, childRecord, field}) {
    const mirrorEntity = field.mirrorFields[0].entity;
    const newValue = {};
    newValue[field.id] = childRecord.id;
    return this.recordService.updateObject({
      id: recordId,
      value: newValue
    }, ['value', 'tags', 'fixedCheckpoint.checkpointset']).pipe(
      mergeMap(() => {
        return this.recordService.retrieveObject(childRecord.id);
      }),
      mergeMap((r: any) => {
        const fieldIds = _.filter(_.flatten(_.map(mirrorEntity.blocks, (b) => b.fields)), f => f.isExpanded).map(f => f.id).join(',')
        const record$ = this.recordService.retrieveObject(recordId, ['value', 'tags', 'fixedCheckpoint.checkpointset']);
        const childRecord$ = this.recordService.retrieveObject(childRecord.id, ['value.' + fieldIds, 'tags']);
        return forkJoin([record$, childRecord$])
      }),
      tap(([record, childRecord]) => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record = record;
          const records = _.filter(ctx.getState().getRecord(recordId).many[field.id].records,
              record => record.id != childRecord.id)
          draft.getRecord(recordId).many[field.id].records = records;
          draft.getRecord(recordId).many[field.id].count--;
          if (mirrorEntity.startingDateField && mirrorEntity.endingDateField
            && childRecord.value[mirrorEntity.startingDateField.id]
            && childRecord.value[mirrorEntity.endingDateField.id]) {
            const timelineRecords = _.filter(ctx.getState().getRecord(recordId).many[field.id].timelineRecords, record => record.id != childRecord.id)
            draft.getRecord(recordId).many[field.id].timelineRecords = timelineRecords;
            draft.getRecord(recordId).many[field.id].timelineCount--;
          }
        });
      })
    );
  }

  @Action(RecordPage.CreateRecord)
  createRecord(ctx, {recordId, fieldsValues, field, indexGroupBy = null}) {
    mutateState(ctx, draft => {
      draft.getRecord(recordId).isCreating.type = 'record';
      draft.getRecord(recordId).isCreating.fields = _.concat(draft.getRecord(recordId).isCreating.fields, field.id);
      draft.getRecord(recordId).isCreating.value = true;
    });
    const mirrorEntity = field.mirrorFields[0].entity;
    const newRecord = {
      entity: mirrorEntity.id,
      space: ctx.getState().getRecord(recordId).record.space,
      value: {}
    };
    let mainField = mirrorEntity.mainField;
    if (field.type === 'ManyToMany' || field.type === 'InverseManyToMany') {
      newRecord['value'][field.mirrorFields[0].id] = [recordId];
      if (mirrorEntity.creationForm) {
        newRecord['form_data'] = fieldsValues;
      } else if (mirrorEntity.mainField) {
        newRecord['value'][mainField.id] = fieldsValues[mainField.id];
      }
    } else {  // InverseOneToMany
      newRecord['value'][field.mirrorFields[0].id] = recordId;
      if (mirrorEntity.creationForm) {
        newRecord['form_data'] = fieldsValues;
      } else if (mainField) {
        newRecord['value'][mainField.id] = fieldsValues[mainField.id];
      }
    }

    if (ctx.getState().getRecord(recordId).many[field.id].mode == 'timeline') {
      _.forEach(fieldsValues, (key) => {
        if (key == 'form_data') {
          newRecord['form_data'] = fieldsValues[key];
        } else if (key != "space") {
          newRecord['value'][key] = fieldsValues[key];
        } else {
          newRecord['space'] = fieldsValues[key];
        }
      });
    }
    return this.recordService.createObject(newRecord, ['value', 'entity']).pipe(
      mergeMap((createdRecord: any) => {
        return this.recordService.retrieveObject(recordId, ['value', 'tags', 'fixedCheckpoint.checkpointset', 'checkpointsets']).pipe(
          tap((record: any) => {
            if (!field.isRedirectAfterCreate) {
              mutateState(ctx, draft => {
                draft.getRecord(recordId).many[field.id].records.unshift(createdRecord);
                draft.getRecord(recordId).many[field.id].count++;
                if (mirrorEntity.startingDateField
                  && mirrorEntity.endingDateField
                  && createdRecord.value[mirrorEntity.startingDateField.id]
                  && createdRecord.value[mirrorEntity.endingDateField.id]) {
                  draft.getRecord(recordId).many[field.id].timelineRecords.splice(indexGroupBy != null ? indexGroupBy + 1 : 0, 0, createdRecord);
                  draft.getRecord(recordId).many[field.id].timelineCount++;
                }
                draft.getRecord(recordId).record = record;
                draft.getRecord(recordId).isCreating.fields = _.pull(draft.getRecord(recordId).isCreating.fields, field.id);
                draft.getRecord(recordId).isCreating.value = false;
              });
            } else {
              let evt = {recordId: createdRecord.id, openModal: createdRecord.entity.isDetailPageInModal};
              openDetailPage(evt);
              mutateState(ctx, draft => {
                draft.getRecord(recordId).isCreating.fields = _.pull(draft.getRecord(recordId).isCreating.fields, field.id);
                draft.getRecord(recordId).isCreating.value = false;
              });
            }
          })
        );
      }),
      catchError(err => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).isCreating.fields = _.pull(draft.getRecord(recordId).isCreating.fields, field.id);
          draft.getRecord(recordId).isCreating.value = false;
        });
        return throwError(err);
      })
    );
  }

  @Action(RecordPage.LoadSiderBlock)
  loadSiderBlock(ctx, {recordId, blockId}) {
    let activeSiderBlock = ctx.getState().getRecord(recordId).entity.blocks.filter(b => b.isSider && b.id === blockId);
    if (activeSiderBlock.length) {
      activeSiderBlock = activeSiderBlock[0];
      const elements$ = [];
      const elems = _.sortBy(_.filter(_.concat(activeSiderBlock.fields, activeSiderBlock.extensions, activeSiderBlock.widgets),
          e => extensions.includes(e.type) || fieldMany.includes(e.type) || widgets.includes(e.type)),
        el => el.potision);
      for (const elem of elems) {
        elements$.push(this.initTabMapping(recordId, elem.type, ctx, false, true, elem))
      }
      elements$.push(this.initTabMapping(recordId, 'fields', ctx, false, true, activeSiderBlock))

      return elements$.length ? forkJoin(elements$) : of();
    }
    return of();
  }

  @Action(RecordPage.SwitchMode, {cancelUncompleted: true})
  switchMode(ctx, {recordId, mode, field}) {
    const hasTimeline = field?.mirrorFields[0].entity.startingDateField && field?.mirrorFields[0].entity.endingDateField;
    const saves = _.cloneDeep(ctx.getState().getRecord(recordId).user.saves);
    mutateState(ctx, draft => {
      draft.getRecord(recordId).many[field.id].mode = mode;
      if (hasTimeline) {
        const entityId = field?.mirrorFields[0].entity.id;
        saves.record[entityId] ? saves.record[entityId].mode = mode : saves.record[entityId] = {mode};
        draft.getRecord(recordId).user.saves = saves;
      }
    });
    const user$ = this.userService.updateObject({id: this.localService.getUser(), saves});
    const fieldMany$ = this.initTabMapping(recordId, field.type, ctx, false, false, field);
    return forkJoin([user$, fieldMany$]).pipe(tap());
  }

  @Action(RecordPage.UpdateCardsFilter)
  updateCardsFilter(ctx, {recordId, fieldId, filters}) {
    mutateState(ctx, draft => {
      draft.getRecord(recordId).filters[fieldId] = filters;
    })

    return this.store.dispatch(new RecordPage.InitBlocks(recordId, ctx.getState().getRecord(recordId).tab));
  }

  @Action(RecordPage.RetrieveTabObjects, {cancelUncompleted: true})
  retrieveTabObjects(ctx, {recordId, element, cursor}) {
    switch (element.type.toLowerCase()) {
      case 'manytomany':
      case 'inverseonetomany':
      case 'inversemanytomany':
        return this.initMfTab(recordId, ctx, false, cursor, false, element);
      case 'attachments':
        return this.initAttachmentsTab(recordId, ctx, false, cursor);
      case 'discussion':
        return this.initDiscussionTab(recordId, ctx, false, cursor);
      case 'history':
        return this.initHistoryTab(recordId, ctx, false, cursor);
      case 'checkpointdiscussion':
        return this.initDiscussionTab(recordId, ctx, false, cursor, element.ready, element.id);
    }
    return of();
  }

  @Action(RecordPage.ToggleRecordTag, {cancelUncompleted: true})
  toggleTag(ctx, {recordId, tag}) {
    mutateState(ctx, draft => {
      const index = _.findIndex(draft.getRecord(recordId).record.tags, (t: any) => t.id === tag.id);
      _.findIndex(draft.getRecord(recordId).record.tags, (t: any) => t.id === tag.id) > -1 ? _.pullAt(draft.getRecord(recordId).record.tags, index) : draft.getRecord(recordId).record.tags.splice(
        _.sortedIndexBy(draft.getRecord(recordId).record.tags, tag, 'position'), index, tag);
    });
    return this.recordService.updateObject({
      id: recordId,
      tags: ctx.getState().getRecord(recordId).record.tags
    }, ['value', 'tags'], true).pipe(
      mergeMap((res: any) => {
        mutateState(ctx, draft => {
          const record = draft.getRecord(recordId)?.record;
          if (!record) return;
          record.tags = res.tags;
          record.activities = res.activities;
        });
        return this.initTabMapping(recordId, 'history', ctx, true);
      })
    );
  }

  @Action(RecordPage.CreateCheckpointChoice)
  createChoice(ctx, {recordId, choice}) {
    const choicesArray = Array.isArray(choice) ? choice : [choice];

    return from(choicesArray.reverse()).pipe( // Reverse the array here
      concatMap((choice: any) => {
        return this.choiceService.createObject(choice).pipe(
          concatMap((c: any) => {
            mutateState(ctx, draft => {
              draft.getRecord(recordId).record.originalCheckpointsets.forEach(cs => {
                cs.checkpoints
                  .filter(cp => cp.id === c.checkpoint)
                  .forEach(cp => {
                    cp.choices.unshift(c);
                  });
              });
            });
            return of();
          })
        );
      })
    );
  }

  @Action(RecordPage.UpdateCheckpointChoice)
  updateChoice(ctx, {recordId, choice}) {
    return this.choiceService.updateObject(choice, [], true).pipe(
      tap((choice: any) => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record.originalCheckpointsets.forEach(cs => {
            cs.checkpoints
              .filter(cp => cp.id === choice.checkpoint).map(cp => {
              cp.choices = cp.choices.map(c => c.id === choice.id ? choice : c);
              return cp;
            })
          })

        });
      })
    );
  }

  @Action(RecordPage.DestroyCheckpointChoice)
  destroyChoice(ctx, {recordId, choice}) {
    return this.choiceService.destroyObject(choice.id).pipe(
      mergeMap((res: any) => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record.originalCheckpointsets.forEach(cs => {
            cs.checkpoints.forEach(cp => {
              if (cp.id === res.checkpoint) {
                cp.choices = cp.choices.filter(c => c.id !== choice.id);
              }
            });
          });
        });
        return of();
      })
    );
  }

  @Action(RecordPage.UpdateCheckpointType)
  updateCheckpointType(ctx, {recordId, checkpoint, type}) {
    mutateState(ctx, draft => {
      draft.getRecord(recordId).record.originalCheckpointsets.forEach(cs => {
        cs.checkpoints.filter(cp => cp.id === checkpoint.id).map(cp => {
          cp.type = type;
        })
      });
    });
    return this.checkpointService.updateObject({id: checkpoint.id, type},
      ['checkpointAttachments', 'tags', 'choices'], true);
  }

  @Action(RecordPage.UpdateCheckpointNotes)
  updateCheckpointNotes(ctx, {recordId, checkpoint, notes}) {
    mutateState(ctx, draft => {
      draft.getRecord(recordId).record.originalCheckpointsets.forEach(cs => {
        cs.checkpoints.filter(cp => cp.id === checkpoint.id).map(cp => {
          cp.notes = notes;
        })
      });
    });
    return this.checkpointService.updateObject({
      id: checkpoint.id,
      notes: notes
    }, ['checkpointAttachments', 'tags', 'choices'], true);
  }

  @Action(RecordPage.CreateCheckpointTag)
  createCheckpointTag(ctx, {recordId, extension, tag}) {
    let obj = {
      title: tag,
      extension: extension,
      record: recordId,
      color: 'Success',
    }
    return this.tagService.createObject(obj).pipe(
      mergeMap((res: any) => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).checkpointTags.unshift(res);
        })
        return of();
      })
    )
  }

  @Action(RecordPage.DeleteCheckpointTag)
  deleteCheckpointTag(ctx, {recordId, tag}) {
    return this.tagService.destroyObject(tag.id).pipe(tap((res: any) => {
      mutateState(ctx, draft => {
        draft.getRecord(recordId).checkpointTags = draft.getRecord(recordId).checkpointTags.filter(t => t.id !== tag.id);
        if (draft.getRecord(recordId).record.originalCheckpointsets.length) {
          draft.getRecord(recordId).record.originalCheckpointsets = draft.getRecord(recordId).record.originalCheckpointsets.map(cs => {
            cs.checkpoints = cs.checkpoints.map(cp => {
              cp.tags = cp.tags.filter(t => t.id !== tag.id);
              return cp;
            })
            return cs;
          })
        }
      })
    }))
  }

  @Action(RecordPage.ToggleCheckpointTag, {cancelUncompleted: true})
  toggleCheckpointTag(ctx, {recordId, checkpoint, tag}) {
    let checkpointTags = checkpoint.tags;
    const index = _.findIndex(checkpointTags, (t: any) => t.id === tag.id);
    if (index > -1) {
      _.pullAt(checkpointTags, index);
    } else {
      checkpointTags.splice(_.sortedIndexBy(checkpointTags, tag, 'id'), index, tag)
    }
    mutateState(ctx, draft => {
      draft.getRecord(recordId).record.originalCheckpointsets.forEach(cs => {
        cs.checkpoints
          .filter(cp => cp.id === checkpoint.id).map(cp => {
          cp.tags = checkpointTags;
        })
      });
    });
    return this.checkpointService.updateObject({
      id: checkpoint.id,
      tags: checkpointTags
    }, ['checkpointAttachments', 'tags', 'choices'], true);
  }

  @Action(RecordPage.UpdateCheckpointState)
  updateCheckPointState(ctx, {recordId, checkpointId, checkpointState}) {
    const mapping = {
      Accepted: 'acceptedCheckPointsCount',
      Fixed: 'fixedCheckPointsCount',
      Rejected: 'rejectedCheckPointsCount',
      Unavailable: 'unavailableCheckPointsCount',
      PartiallyConform: 'partiallyConformCheckPointsCount',
      null: 'emptyCheckPointsCount',
      evaluated: 'evaluatedCheckPointsCount'
    }
    const state = ctx.getState().getRecord(recordId);
    const checkpoints = _.flatten(_.values(state.checkpoints));
    const checkpoint = _.cloneDeep(_.find(checkpoints, {"id": checkpointId}));
    const oldState = checkpoint.state;
    const newState = checkpointState;

    mutateState(ctx, draft => {
      draft.getRecord(recordId).checkpointsets.forEach((cs: any) => {
        if (cs.id === checkpoint.checkpointset) {
          cs[mapping[newState]]++;
          if (cs[mapping[oldState]]) {
            cs[mapping[oldState]]--;
          }
        }
      });
      draft.getRecord(recordId).checkpoints[checkpoint.checkpointset].forEach((cp: any) => {
        if (cp.id === checkpointId) {
          cp.state = newState;
        }
      });
      if (oldState) {
        draft.getRecord(recordId).record[mapping[oldState]]--;
      }
      if (newState) {
        draft.getRecord(recordId).record[mapping[newState]]++;
      }
    });

    return this.checkpointService.updateObject({id: checkpoint.id, state: newState}).pipe(
      mergeMap((checkpoint: any) => {
        const checkpointset$ = this.checkpointsetService.retrieveObject(checkpoint.checkpointset);
        const record$ = this.recordService.updateObject({id: state.record.id},
          ['value', 'tags', 'fixedCheckpoint.checkpointset'], true);
        return forkJoin(...[checkpointset$, record$]);
      }),
      tap(([checkpointset, record]) => {
        if (!ctx.getState().isRecord(state.record.id)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).checkpointsets.map((cs: any) => {
            if (cs.id === checkpointset.id) {
              return checkpointset;
            }
            return cs;
          });
        });
        return of(record)
      })
    );
  }

  @Action(RecordPage.EvaluateCheckpoint)
  evaluateCheckpoint(ctx, {recordId, checkpointId, value, state}) {
    const recordState = ctx.getState().getRecord(recordId);
    const checkpoints = _.flatten(_.values(recordState.checkpoints));
    const checkpoint = _.cloneDeep(_.find(checkpoints, {"id": checkpointId}));
    const oldState = checkpoint.state;
    const mapping = {
      Accepted: 'acceptedCheckPointsCount',
      Fixed: 'fixedCheckPointsCount',
      Rejected: 'rejectedCheckPointsCount',
      Unavailable: 'unavailableCheckPointsCount',
      PartiallyConform: 'partiallyConformCheckPointsCount',
      null: 'emptyCheckPointsCount',
      Evaluated: 'evaluatedCheckPointsCount'
    }
    let newState;

    if (state) {
      newState = value || value === 0 ? state : null;
    } else {
      newState = value || value === 0 ? 'Evaluated' : null;
    }

    mutateState(ctx, draft => {
      draft.getRecord(recordId).checkpointsets.forEach((cs: any) => {
        if (cs.id === checkpoint.checkpointset) {
          cs[mapping[oldState]]--;
          cs[mapping[newState]]++;
        }
      });
      draft.getRecord(recordId).checkpoints[checkpoint.checkpointset].forEach((cp: any) => {
        if (cp.id === checkpointId) {
          cp.evaluation = value;
          cp.state = newState;
        }
      });
      draft.getRecord(recordId).record[mapping[oldState]]--;
      draft.getRecord(recordId).record[mapping[newState]]++;
    });
    return this.checkpointService.updateObject({id: checkpointId, evaluation: value, state: newState},
      ['checkpointAttachments', 'tags', 'choices']).pipe(
      mergeMap((checkpoint: any) => {
        const checkpointset$ = this.checkpointsetService.retrieveObject(checkpoint.checkpointset);
        const record$ = this.recordService.updateObject({id: recordState.record.id},
          ['value', 'tags', 'fixedCheckpoint.checkpointset'], true);
        return forkJoin(...[checkpointset$, record$]);
      }),
      tap(([checkpointset, record]) => {
        if (!ctx.getState().isRecord(recordState.record.id)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).checkpointsets.map((cs: any) => {
            if (cs.id === checkpointset.id) {
              return checkpointset;
            }
            return cs;
          });
        });
        return of(record)
      })
    );
  }

  @Action(RecordPage.CreateCheckpointAttachment)
  createCheckpointAttachment(ctx, {recordId, checkpoint, file, isStandard}) {
    const attachment = {
      file: file,
      author: this.localService.getUser(),
      checkpoint: checkpoint.id,
      is_standard: isStandard
    };
    return this.checkpointAttachmentsService.createObject(attachment).pipe(
      tap((res: any) => {
        mutateState(ctx, draft => {
          if (!isStandard) {
            draft.getRecord(recordId).checkpoints[checkpoint.checkpointset].forEach((cp: any) => {
              if (cp.id === checkpoint.id) {
                cp.checkpointAttachments.unshift(res);
              }
            });
          } else {
            draft.getRecord(recordId).record.originalCheckpointsets = draft.getRecord(recordId).record.originalCheckpointsets.map(cs => {
              cs.checkpoints = cs.checkpoints.map(cp => {
                if (cp.id == checkpoint.id) {
                  cp.checkpointAttachments.unshift(res);
                }
                return cp;
              });
              return cs;
            });
          }
        });
      })
    )
  }

  @Action(RecordPage.DestroyCheckpointAttachment)
  destroyCheckpointAttachment(ctx, {recordId, checkpoint, fileId, isStandard}) {
    mutateState(ctx, draft => {
      if (!isStandard) {
        draft.getRecord(recordId).checkpoints[checkpoint.checkpointset].forEach((cp: any) => {
          if (cp.id === checkpoint.id) {
            cp.checkpointAttachments = cp.checkpointAttachments.filter(attachment => attachment.id !== fileId);
          }
        });
      } else {
        draft.getRecord(recordId).record.originalCheckpointsets = draft.getRecord(recordId).record.originalCheckpointsets.map(cs => {
          cs.checkpoints = cs.checkpoints.map(cp => {
            if (cp.id === checkpoint.id) {
              cp.checkpointAttachments = cp.checkpointAttachments.filter(attachment => attachment.id !== fileId);
              return cp;
            }
            return cp;
          });
          return cs;
        });
      }
    });
    return this.checkpointAttachmentsService.destroyObject(fileId);
  }

  @Action(RecordPage.CreateChecklistObject)
  createChecklistObject(ctx, {recordId, type, object}) {
    let model = checklistObjects[type];
    let service = this[model.service];

    return service.createObject(object).pipe(
      catchError(err => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).isCreating.type = 'checkpoint';
          draft.getRecord(recordId).isCreating.value = false;
        })
        return throwError(err);
      }),
      mergeMap((res: any) => {
        return this.recordService.retrieveObject(recordId, ['originalCheckpointsets.checkpoints.[checkpointAttachments,tags,choices]'])
      }),
      tap((record: any) => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record.originalCheckpointsets = record.originalCheckpointsets;
          draft.getRecord(recordId).isCreating.type = 'checkpoint';
          draft.getRecord(recordId).isCreating.value = false;
        });
      })
    );
  }

  @Action(RecordPage.UpdateChecklistObject)
  updateChecklistObject(ctx, {recordId, type, object}) {
    let model = checklistObjects[type];
    let service = this[model.service];

    return service.updateObject(object).pipe(
      mergeMap((res: any) => {
        return this.recordService.retrieveObject(recordId, ['originalCheckpointsets.checkpoints.[checkpointAttachments,tags,choices]'])
      }),
      tap((record: any) => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record.originalCheckpointsets = record.originalCheckpointsets;
          draft.getRecord(recordId).isCreating.type = 'checkpoint';
          draft.getRecord(recordId).isCreating.value = false;
        })
      })
    )
  }

  @Action(RecordPage.UpdateChecklistObjects, {cancelUncompleted: true})
  updateChecklistObjects(ctx, {recordId, type, objects}) {
    let model = checklistObjects[type];
    let service = this[model.parentService];

    let staleObjects$ = [];

    if (objects['staleObjects']) {
      staleObjects$ = objects['staleObjects'].map(staleObj => service.updateObject({id: staleObj}));
      objects['staleObjects'].map(staleObj => {
        this.cache.clearContains('checkpoints', [[{}, {cached: {body: 'checkpointset'}}]], staleObj)
        this.cache.clearContains('checkpointsets', [[{}, {cached: {param: 'id'}}]], staleObj);
      });
      objects = objects['objects'];
    }

    let parentId = objects[0][model.parent];

    let payload = model.name + '_';
    this.cache.clearContains('checkpoints', [[{}, {cached: {body: 'checkpointset'}}]], parentId)
    this.cache.clearContains('checkpointsets', [[{}, {cached: {param: 'id'}}]], parentId);
    return service.updateObject({id: parentId, [payload]: objects}).pipe(
      mergeMap((res: any) => staleObjects$.length ? forkJoin(staleObjects$) : of([])),
      mergeMap((res: any) => {
        return this.recordService.retrieveObject(recordId, ['originalCheckpointsets.checkpoints.[checkpointAttachments,tags,choices]'])
      }),
      tap((record: any) => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record.originalCheckpointsets = record.originalCheckpointsets;
        });
      })
    );
  }

  @Action(RecordPage.DeleteChecklistObject)
  deleteChecklistObject(ctx, {recordId, type, id}) {
    let model = checklistObjects[type];
    let service = this[model.service];

    return service.destroyObject(id).pipe(
      mergeMap((res: any) => {
        return this.recordService.retrieveObject(recordId, ['originalCheckpointsets.checkpoints.[checkpointAttachments,tags,choices]'])
      }),
      tap((record: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record.originalCheckpointsets = record.originalCheckpointsets;
        });
      })
    );
  }

  @Action(RecordPage.UpdateCheckpointComment)
  updateCheckpointComment(ctx, {recordId, checkpointId, comment}) {
    const state = ctx.getState().getRecord(recordId);
    const checkpoints = _.flatten(_.values(state.checkpoints));
    const checkpoint = _.cloneDeep(_.find(checkpoints, {"id": checkpointId}));
    return this.checkpointService.updateObject({id: checkpointId, comment}, ['checkpointAttachments']).pipe(
      tap((res: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).checkpoints[checkpoint.checkpointset].forEach((cp: any) => {
            if (cp.id === checkpointId) {
              cp.comment = res.comment;
            }
          });
        });
      })
    );
  }

  @Action(RecordPage.UpdateChecklistRecord)
  updateChecklistRecord(ctx, {recordId, checklistRecordId}) {
    let record = _.cloneDeep(ctx.getState().getRecord(recordId).record);
    record.checklistRecord = checklistRecordId;
    mutateState(ctx, draft => {
      draft.getRecord(recordId).isUpdatingChecklist = {
        checklist: checklistRecordId,
        value: true
      };
    });
    return this.recordService.updateObject({
      id: recordId,
      checklistRecord: record.checklistRecord
    }, ['value']).pipe(
      tap((res: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record.checklistRecord = res.checklistRecord;
          draft.getRecord(recordId).record.mainCheckpointset = res.mainCheckpointset;
          draft.getRecord(recordId).isUpdatingChecklist = {
            checklist: '',
            value: false
          };
        });
        return this.store.dispatch(new RecordPage.InitBlocks(recordId, ctx.getState().getRecord(recordId).tab));
      })
    );
  }

  @Action(RecordPage.CreateComment)
  publishComment(ctx, {object, recordId, commentText, isCheckpoint}) {
    const newComment = {
      author: this.localService.getUser(),
      text: commentText,
      ...(isCheckpoint ? {checkpoint: object.id} : {record: object.id})
    };
    mutateState(ctx, draft => {
      const comments = isCheckpoint
        ? draft.getRecord(recordId).checkpointComments[object.id]
        : draft.getRecord(recordId).comments;

      if (comments) {
        comments.unshift({author: ctx.getState().getRecord(recordId).user, text: commentText, mentions: []});
      }
    });
    return this.commentService.createObject(newComment, ['author', 'mentions']).pipe(mergeMap(comment => {
        mutateState(ctx, draft => {
          if (isCheckpoint) {
            draft.getRecord(recordId).checkpoints[object.checkpointset]?.find(cp => cp.id === object.id).comments.unshift(comment['id']);
            draft.getRecord(recordId).checkpointComments[object.id][0] = comment;
          }
        });
        return forkJoin([of(comment), this.recordService.retrieveObject(recordId, ['value', 'tags'])]);
      }),
      mergeMap(([comment, record]) => {
        if (!ctx.getState().isRecord(recordId) || isCheckpoint) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record = RecordPageState.loadPrevState(recordId, ctx, record);
        });
        return this.store.dispatch(new RecordPage.RefreshBlocks(recordId, ctx.getState().getRecord(recordId).tab));
      })
    );
  }

  @Action(RecordPage.UpdateComment)
  editComment(ctx, {object, commentText, recordId, commentId, isCheckpoint}) {
    mutateState(ctx, draft => {
      const comments = isCheckpoint
        ? draft.getRecord(recordId).checkpointComments[object.id]
        : draft.getRecord(recordId).comments;

      if (comments) {
        _.find(comments, {id: commentId}).text = commentText;
      }
    });
    return this.commentService.updateObject({id: commentId, text: commentText}, ['author', 'mentions']).pipe(
      mergeMap(updatedComment => {
        mutateState(ctx, draft => {
          const comments = isCheckpoint
            ? draft.getRecord(recordId).checkpointComments[object.id]
            : draft.getRecord(recordId).comments;

          if (comments) {
            comments.splice(_.findIndex(comments, (c: any) => c.id === commentId), 1, updatedComment);
          }
        });
        return this.recordService.retrieveObject(recordId, ['value', 'tags']);
      }),
      tap((record: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record = RecordPageState.loadPrevState(recordId, ctx, record);
        });
      })
    );
  }

  @Action(RecordPage.DestroyComment)
  deleteComment(ctx, {object, recordId, commentId, isCheckpoint}) {
    mutateState(ctx, draft => {
      if (!isCheckpoint) {
        _.remove(draft.getRecord(recordId).comments, (c: any) => c.id === commentId);
        _.remove(draft.getRecord(recordId).record.comments, (c: any) => c === commentId);
      } else {
        _.remove(draft.getRecord(recordId).checkpointComments[object.id], (c: any) => c.id === commentId);
        _.remove(draft.getRecord(recordId).checkpoints[object.checkpointset]?.find(cp => cp.id === object.id)?.comments, (c: any) => c === commentId);
      }
    });
    return this.commentService.destroyObject(commentId).pipe(
      mergeMap(res => {
        return this.recordService.retrieveObject(recordId, ['value', 'tags']);
      }),
      tap((record: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record = RecordPageState.loadPrevState(recordId, ctx, record);
        });
      })
    );
  }

  @Action(RecordPage.UpdateReaction)
  updateReaction(ctx, {object, emojis, recordId, commentId, isCheckpoint}) {
    const reactions = [
      {user: this.localService.getUser(), emoji: emojis},
    ];
    return this.commentService.updateObject({id: commentId, reactions}, ['author', 'mentions', 'reactions']).pipe(
      mergeMap(updatedComment => {
        mutateState(ctx, draft => {
          const comments = isCheckpoint
            ? draft.getRecord(recordId).checkpointComments[object.id]
            : draft.getRecord(recordId).comments;
          if (comments) {
            comments.splice(_.findIndex(comments, (c: any) => c.id === commentId), 1, updatedComment);
          }
        });
        return this.recordService.retrieveObject(recordId, ['value', 'tags']);
      }),
      tap((record: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record = RecordPageState.loadPrevState(recordId, ctx, record);
        });
      })
    );
  }

  @Action(RecordPage.CreateAttachment)
  createAttachment(ctx, {recordId, file}) {
    mutateState(ctx, draft => {
      draft.getRecord(recordId).attachments.unshift({id: -1});
      draft.getRecord(recordId).record.attachments = draft.getRecord(recordId).attachments.length;
    });
    const newAttachment = {
      file,
      record: recordId,
      author: this.localService.getUser()
    };
    return this.attachmentService.createObject(newAttachment, ['author']).pipe(
      mergeMap(attachment => {
        const record$ = this.recordService.updateObject({id: recordId}, ['value', 'tags'], true);
        return forkJoin([of(attachment), record$]);
      }),
      mergeMap(([attachment, record]) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          const index = _.findIndex(draft.getRecord(recordId).attachments, {id: -1});
          draft.getRecord(recordId).attachments.splice(index, 1, attachment);
          draft.getRecord(recordId).record = RecordPageState.loadPrevState(recordId, ctx, record);
        });
        return this.store.dispatch(new RecordPage.RefreshBlocks(recordId, ctx.getState().getRecord(recordId).tab));
      })
    );
  }

  @Action(RecordPage.DestroyAttachment)
  destroyAttachment(ctx, {recordId, id}) {
    mutateState(ctx, draft => {
      _.remove(draft.getRecord(recordId).attachments, (a: any) => a.id === id);
      _.remove(draft.getRecord(recordId).record.attachments, (a: any) => a === id);
    });
    return this.attachmentService.destroyObject(id).pipe(
      mergeMap(res => {
        return this.recordService.updateObject({id: recordId}, ['value', 'tags'], true);
      }),
      tap((record: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).record = RecordPageState.loadPrevState(recordId, ctx, record);
        });
        return this.store.dispatch(new RecordPage.InitBlocks(recordId, ctx.getState().getRecord(recordId).tab, true));
      })
    );
  }

  @Action(RecordPage.CreateFixingRecord)
  createFixingRecord(ctx, {recordId, spaceId, entityId, entityFieldId, title, checkpointId}) {
    const state = ctx.getState().getRecord(recordId);
    const checkpoints = _.flatten(_.values(state.checkpoints));
    const checkpoint = _.cloneDeep(_.find(checkpoints, {"id": checkpointId}));

    mutateState(ctx, draft => {
      draft.getRecord(recordId).isCreatingFixing = {
        checkpoint: checkpointId,
        value: true
      };
    });
    const newRecord = {};
    newRecord['entity'] = entityId;
    newRecord['space'] = spaceId;
    newRecord['value'] = {};
    newRecord['fixed_checkpoint'] = checkpointId;
    if (entityFieldId) {
      newRecord['value'][entityFieldId] = title;
    }
    return this.recordService.createObject(newRecord, ['value', 'fixedCheckpoint.checkpointset', 'tags']).pipe(tap((res: any) => {
      mutateState(ctx, draft => {
        draft.getRecord(recordId).isCreatingFixing = {
          checkpoint: '',
          value: false
        }
        draft.getRecord(recordId).checkpoints[checkpoint.checkpointset].forEach((cp: any) => {
          if (cp.id === checkpointId) {
            if (cp.fixingRecords == '{{FORBIDDEN}}') cp.fixingRecords = [];
            cp.fixingRecords.unshift(res);
            cp.state = res.fixedCheckpoint.state;
          }
        });
      });
    }, (err) => {
      mutateState(ctx, draft => {
        draft.getRecord(recordId).isCreatingFixing = {
          checkpoint: '',
          value: false
        }
      });
    }));
  }

  @Action(RecordPage.UpdateRecordFields)
  updateRecordFields(ctx, {record, fieldValueMapping}) {
    let newValue = {};
    Object.keys(fieldValueMapping).forEach((key) => {
      newValue[key] = fieldValueMapping[key];
    });
    return this.recordService.updateObject({
      id: record.id,
      value: newValue
    }, ['value', 'tags', 'fixedCheckpoint.checkpointset']);
  }

  @Action(RecordPage.ChangeGanttScope)
  ChangeGanttScope(ctx, {recordId, scope, field}) {
    const saves = _.cloneDeep(ctx.getState().getRecord(recordId).user.saves);
    const config = JSON.stringify({scope});
    const entityId = field?.mirrorFields[0].entity.id;
    saves.record[entityId] ? saves.record[entityId].timelineConfig = config : saves.record[entityId] = {timelineConfig: config};
    mutateState(ctx, draft => {
      draft.getRecord(recordId).user.saves = saves;
    });
    return this.userService.updateObject({
      id: this.localService.getUser(),
      saves
    }).pipe(tap());
  }

  @Action(RecordPage.RetrieveUnscheduledRecords)
  retrieveUnscheduledRecords(ctx, {recordId, queryParams, cursor, field}) {
    mutateState(ctx, draft => {
      draft.getRecord(recordId).many[field.id].isUnscheduledReady = false;
      draft.getRecord(recordId).many[field.id].unscheduledRecords = cursor === -1 ? [] : draft.getRecord(recordId).many[field.id].unscheduledRecords;
    });

    const fieldId = field.id;
    const mirrorField = field.mirrorFields[0];
    const entity = mirrorField.entity;
    const filters = ctx.getState().getRecord(recordId).filters[fieldId];
    const qp = {
      entity: mirrorField.entity.id,
      origin_field: mirrorField.name,
      [mirrorField.name]: recordId, ...filters, ...queryParams,
      mode: 'timeline'
    };

    return this.recordService.retrieveUnscheduledRecords(qp, entity, cursor).pipe(
      tap((response: any) => {
        mutateState(ctx, draft => {
          const currentRecords = draft.getRecord(recordId).many[field.id].unscheduledRecords;
          draft.getRecord(recordId).many[field.id].unscheduledRecords = _.uniqBy(currentRecords.concat(response.data), (r: any) => r.id);
          draft.getRecord(recordId).many[field.id].unscheduledCount = response.count;
          draft.getRecord(recordId).many[field.id].unscheduledNextCursor = response.nextCursor;
          draft.getRecord(recordId).many[field.id].isUnscheduledReady = true;
        });
      }));
  }

  @Action(RecordPage.RecordDrag)
  RecordDrag(ctx, {recordId, data}) {
    const entity = data.field.mirrorFields[0].entity;
    let currentIndex, targetIndex, currentRecord, field;
    const dateFieldId = (data.timeline && entity.startingDateField) ? entity.startingDateField.id : entity.endingDateField.id;
    targetIndex = data.index;
    field = data.field;
    mutateState(ctx, draft => {
      if (data.newDate && !data.record.value[dateFieldId]) {
        currentIndex = _.findIndex(draft.getRecord(recordId).many[field.id].unscheduledRecords, (r: any) => r.id === data.record.id);
        currentRecord = _.nth(draft.getRecord(recordId).many[field.id].unscheduledRecords, currentIndex);
        const updatedRecord = _.cloneDeep(currentRecord);
        _.remove(draft.getRecord(recordId).many[field.id].unscheduledRecords, (r: any) => r.id === data.record.id);
        draft.getRecord(recordId).many[field.id].unscheduledCount--;
        if (data.newDate?.hasOwnProperty('start')) {
          if (entity.startingDateField) updatedRecord.value[entity.startingDateField.id] = data.newDate.start;
          updatedRecord.value[entity.endingDateField.id] = data.newDate.end;
        } else {
          updatedRecord.value[dateFieldId] = data.newDate;
        }
        draft.getRecord(recordId).many[field.id].timelineRecords = [...draft.getRecord(recordId).many[field.id].timelineRecords.slice(0, data.index), updatedRecord, ...draft.getRecord(recordId).many[field.id].timelineRecords.slice(data.index)]
      }
    });
    let fieldValueMapping = {};
    if (data.newDate?.hasOwnProperty('start')) {
      fieldValueMapping = {
        ...(entity.startingDateField && {[entity.startingDateField.id]: data.newDate.start}),
        [entity.endingDateField.id]: data.newDate.end,
        [data.groupByField]: data.groupByValue
      };
    } else {
      fieldValueMapping = {
        [dateFieldId]: data.newDate,
        [data.groupByField]: data.groupByValue
      };
    }
    let newValue = {};
    Object.keys(fieldValueMapping).forEach((key) => {
      newValue[key] = fieldValueMapping[key];
    });
    return this.recordService.updateObject({
      id: data.record.id,
      value: newValue
    }, ['value', 'tags', 'fixedCheckpoint', 'originalCheckpointsets']).pipe(
      tap((record: any) => {
          mutateState(ctx, draft => {
            draft.getRecord(recordId).many[field.id].timelineRecords = _.map(draft.getRecord(recordId).many[field.id].timelineRecords, (r: any) => r.id === record.id ? record : r);
          });
        }
      ),
      catchError((error) => {
        mutateState(ctx, draft => {
          if (data.newDate && !data.record.value[entity.startingDateField.id]) {
            draft.unscheduledCount++;
            draft.getRecord(recordId).many[field.id].unscheduledRecords = [...draft.getRecord(recordId).many[field.id].unscheduledRecords.slice(0, currentIndex), currentRecord, ...draft.getRecord(recordId).many[field.id].unscheduledRecords.slice(currentIndex)]
            _.pullAt(draft.records, targetIndex);
          }
        });
        throw error;
      })
    )
  }

  private initTabMapping(recordId: number, key: string, ctx: any, isUpdate?: boolean, ready: boolean = false, element = null) {
    switch (key.toLowerCase()) {
      case 'audit':
        return this.initAuditTab(recordId, ctx, isUpdate, ready, element);
      case 'checklist':
        return this.initChecklistTab(recordId, ctx, isUpdate, ready, element);
      case 'manytomany':
      case 'inverseonetomany':
      case 'inversemanytomany':
        return this.initMfTab(recordId, ctx, isUpdate, -1, ready, element);
      case 'fields':
        return this.initTabbedFields(recordId, ctx, isUpdate, element);
      case 'attachments':
        return this.initAttachmentsTab(recordId, ctx, isUpdate, -1, ready);
      case 'discussion':
        return this.initDiscussionTab(recordId, ctx, isUpdate, -1, ready);
      case 'history':
        return this.initHistoryTab(recordId, ctx, isUpdate, -1, ready);
    }
    return of([]);
  }

  private initTabbedFields(recordId: number, ctx: any, isUpdate?: boolean, block?: any) {
    const record = ctx.getState().getRecord(recordId).record;
    const fieldIds = record.fields.filter(f => block.fields.filter(bf => fields.includes(bf.type)).find(bf => bf.id === f.id) && f?.isActiveDetail).map(f => f.id).join(',');
    if (!fieldIds) {
      return of([]);
    }
    return this.recordService.retrieveObject(recordId, ['value.' + fieldIds, 'tags']).pipe(!isUpdate ? delay(0) : tap(),
      tap((recordRes: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          let fieldIdsArr = fieldIds.split(',').filter(id => id !== '').map(id => +id);
          let clonedRecord = _.cloneDeep(ctx.getState().getRecord(recordId).record);
          for (let id of fieldIdsArr) {
            clonedRecord.value = {
              ...clonedRecord.value,
              [id]: recordRes.value[id]
            };
          }
          draft.getRecord(recordId).record = clonedRecord;
        });
      })
    );
  }

  private initMfTab(recordId: number, ctx: any, isUpdate?: boolean, cursor = -1, ready = false, element?: any) {
    if (isUpdate || !ctx.getState().getRecord(recordId).many[element.id]) {
      return of(null);
    }
    if (ctx.getState().getRecord(recordId).many[element.id].mode === 'cards') {
      return this.retrieveCardsRecords(recordId, ctx, cursor, ready, element);
    } else {
      return this.retrieveTimelineRecords(recordId, ctx, cursor, ready, element);
    }
  }

  private initHistoryTab(recordId: number, ctx: any, isUpdate?: boolean, cursor = -1, ready: boolean = false) {
    if (cursor === -1 && !isUpdate && !ready) {
      mutateState(ctx, draft => {
        draft.getRecord(recordId).activities = [];
      });
    }
    const immediate = cursor > 0 || isUpdate || ready;
    const pageSize = ready ? ctx.getState().getRecord(recordId).activities : isUpdate ? ctx.getState().getRecord(recordId).activities.length + 1 : 10;
    return this.activityService.retrieveObjects({
      record: recordId,
      cursor: cursor,
      page_size: pageSize
    }, ["actor"]).pipe(!isUpdate ? delay(0) : tap(),
      map((res: any) => res.data.map(obj => of(obj))),
      this.loadTabData(this.actions$, (res: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          if (ready) {
            draft.getRecord(recordId).activities = res;
          } else {
            if (isUpdate) {
              draft.getRecord(recordId).activities.unshift(res[0]);
            } else {
              if (cursor === -1) {
                draft.getRecord(recordId).activities.push(res);
              } else {
                draft.getRecord(recordId).activities = _.uniqBy(_.concat(draft.getRecord(recordId).activities, res), 'id');
              }
            }
          }

        });
      }, immediate)
    );
  }

  private initAttachmentsTab(recordId: number, ctx: any, isUpdate?: boolean, cursor = -1, ready: boolean = false) {
    if (cursor === -1 && !ready) {
      mutateState(ctx, draft => {
        draft.getRecord(recordId).attachments = [];
      });
    }
    const immediate = cursor > 0 || ready;
    const pageSize = ready ? ctx.getState().getRecord(recordId).attachments : 10;
    return this.attachmentService.retrieveObjects({
      record: recordId,
      cursor: cursor,
      page_size: pageSize
    }, ['author']).pipe(!isUpdate ? delay(0) : tap(),
      map((res: any) => res.data.map(obj => of(obj))),
      this.loadTabData(this.actions$, (res: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          if (ready) {
            draft.getRecord(recordId).attachments = res;
          } else {
            if (cursor === -1) {
              draft.getRecord(recordId).attachments.push(res);

            } else {
              draft.getRecord(recordId).attachments = _.uniqBy(_.concat(draft.getRecord(recordId).attachments, res), 'id');

            }
          }
        });
      }, immediate, () => {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).attachments = [];
        });
      })
    );
  }

  private initAuditTab(recordId: number, ctx: any, isUpdate: boolean, ready: boolean, element: any) {
    const record = ctx.getState().getRecord(recordId).record;
    const entity = ctx.getState().getRecord(recordId).entity;
    if (!ready && !isUpdate) {
      mutateState(ctx, draft => {
        draft.getRecord(recordId).isTabReady = false;
        draft.getRecord(recordId).checkpointTags = [];
      });
    }
    if ((isUpdate && !ready && (_.isEmpty(element.filter) || record?.checklistRecord || record?.checkpointsets.length || !entity?.checklistsEntity))) {
      return of(null);
    }

    mutateState(ctx, draft => {
      draft.getRecord(recordId).isUpdatingChecklist = {value: true};
    });

    const tags$ = this.tagService.retrieveObjects({extension: element.id, ordering: "id"}).pipe(
      map((res: any) => res.data.map(obj => of(obj))),
      mergeMap((observables: any) => observables.length ? forkJoin(observables) : of([])));

    if (record && !record.checklistRecord && !record.checkpointsets.length && entity.checklistsEntity) {
      const checklistsRecords$ = this.recordService.retrieveObjects({
        record: record.id,
        space: entity.spaces,
        entity: entity.checklistsEntity
      });
      return checklistsRecords$.pipe(
        mergeMap((records: any) => {
          if (records.data.length != 0) {
            return this.entityService.retrieveMetadata(records.data[0].entity).pipe(tap(ent => {
              _.map(records.data, r => r.entity = ent);
              mutateState(ctx, draft => {
                draft.getRecord(recordId).isUpdatingChecklist = false;
                if (draft.getRecord(recordId).record?.id === record.id) {
                  draft.getRecord(recordId).entity.checklistsRecords = records.data.filter(record => record.allCheckPointsCountForChecklist);
                }
              });
            }));
          } else {
            mutateState(ctx, draft => {
              draft.getRecord(recordId).isUpdatingChecklist = false;
              if (draft.getRecord(recordId).record?.id === record.id) {
                draft.getRecord(recordId).entity.checklistsRecords = [];
              }
            });
            return of([]);
          }
        })
      )
    } else {
      return tags$.pipe(
        mergeMap((tags) => {
          mutateState(ctx, draft => {
            if (!_.isEqual(draft.getRecord(recordId).checkpointTags, tags)) {
              draft.getRecord(recordId).checkpointTags = tags;
            }
          });
          return this.checkpointsetService.retrieveObjects({audit_record: record.id});
        }),
        map((res: any) => res.data.map(obj => of(obj))),
        mergeMap((observables$: any) => {
          if (!observables$.length) return of([]);
          return concat(...observables$).pipe(
            concatMap((checkpointSet: any) => {
              if (!checkpointSet.checkpoints.length) return of([]);
              const checkpoints$ = checkpointSet.checkpoints.map((cp) => this.checkpointService.retrieveObject(cp,
                ['tags', 'checkpointAttachments', 'fixingRecords.[tags,value]', 'choices']));
              return forkJoin(...checkpoints$).pipe(
                tap((checkpoints: any[]) => {
                  if (!ctx.getState().isRecord(recordId)) return of();
                  mutateState(ctx, draft => {
                    if (!isUpdate && !ready && !!!_.find(draft.getRecord(recordId).checkpointsets, {'id': checkpointSet.id})) {
                      draft.getRecord(recordId).checkpointsets.push(checkpointSet);
                      if (!_.isEqual(draft.getRecord(recordId).checkpoints[checkpointSet.id], checkpoints)) {
                        draft.getRecord(recordId).checkpoints[checkpointSet.id] = checkpoints;
                      }
                    } else {
                      const index = _.findIndex(draft.getRecord(recordId).checkpointsets, {id: checkpointSet.id});
                      if (!_.isEqual(draft.getRecord(recordId).checkpointsets[index], checkpointSet)) {
                        draft.getRecord(recordId).checkpointsets[index] = checkpointSet;
                      }
                      if (!_.isEqual(draft.getRecord(recordId).checkpoints[checkpointSet.id], checkpoints)) {
                        draft.getRecord(recordId).checkpoints[checkpointSet.id] = checkpoints;
                      }
                    }
                  });
                }));
            }),
            takeUntil(this.actions$.pipe(ofAction(RecordPage.DestroyRecord)))
          )
        }),
        finalize(() => {
          if (!ctx.getState().isRecord(recordId)) return of();
          mutateState(ctx, draft => {
            draft.getRecord(recordId).isTabReady = true;
          })
        })
      )
    }
  }

  private initChecklistTab(recordId: number, ctx: any, isUpdate: boolean, ready: boolean, element: any) {
    if (!ready && !isUpdate) {
      mutateState(ctx, draft => {
        draft.getRecord(recordId).checkpointTags = [];
      });
    }
    const tags$ = this.tagService.retrieveObjects({extension: element.id, ordering: "id"}).pipe(
      map((res: any) => res.data.map(obj => of(obj))),
      mergeMap((observables: any) => observables.length ? forkJoin(observables) : of([])));
    return tags$.pipe(
      tap((tags: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          draft.getRecord(recordId).checkpointTags = tags;
        });
      }));
  }

  private initDiscussionTab(recordId: number, ctx: any, isUpdate?: boolean, cursor = -1, ready: boolean = false, checkpointId?: number) {
    if (cursor === -1 && !ready) {
      mutateState(ctx, draft => {
        if (checkpointId) {
          draft.getRecord(recordId).checkpointComments[checkpointId] = [];
        } else {
          draft.getRecord(recordId).comments = [];
        }
      });
    }
    const immediate = cursor > 0 || ready;
    const comments = checkpointId ? ctx.getState().getRecord(recordId).checkpointComments[checkpointId] : ctx.getState().getRecord(recordId).comments
    const pageSize = ready ? (Array.isArray(comments) ? comments.length : comments) || 10 : 10;

    return this.commentService.retrieveObjects({
      ...(checkpointId ? {checkpoint: checkpointId} : {record: recordId}),
      cursor: cursor,
      page_size: pageSize
    }, ['author', 'mentions']).pipe(!isUpdate ? delay(0) : tap(),
      map((res: any) => res.data.map(obj => of(obj))),
      this.loadTabData(this.actions$, (res: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();
        mutateState(ctx, draft => {
          if (ready) {
            if (checkpointId) {
              draft.getRecord(recordId).checkpointComments[checkpointId] = res;
            } else {
              draft.getRecord(recordId).comments = res;
            }
          } else {
            if (cursor === -1) {
              if (checkpointId) {
                draft.getRecord(recordId).checkpointComments[checkpointId].push(res);
              } else {
                draft.getRecord(recordId).comments.push(res);
              }
            } else {
              if (checkpointId) {
                draft.getRecord(recordId).checkpointComments[checkpointId] = _.uniqBy(_.concat(draft.getRecord(recordId).checkpointComments[checkpointId], res), 'id');
              } else {
                draft.getRecord(recordId).comments = _.uniqBy(_.concat(draft.getRecord(recordId).comments, res), 'id');
              }
            }
          }
        });
      }, immediate)
    );
  }

  private retrieveCardsRecords(recordId: number, ctx: any, cursor: number, ready: boolean = false, field?: any) {
    const fieldId = field.id;
    const mirrorField: any = field.mirrorFields[0];
    const filters = ctx.getState().getRecord(recordId).filters[fieldId];
    const urlTree = this.router.parseUrl(this.router.url);
    const primary = urlTree.root.children['primary'];
    const auxiliary = urlTree.root.children['modal'];
    let queryParams = locationToQueryParams(this.location.path());

    if (primary && auxiliary) {
      queryParams = queryParams['timelineConfig'] ? {"timelineConfig": queryParams['timelineConfig']} : {};
    }

    const qp = {
      entity: mirrorField.entity.id,
      origin_field: mirrorField.name,
      [mirrorField.name]: recordId, ...filters, ...queryParams,
      mode: 'cards'
    };

    let pageSize = 10;
    if (ready) {
      pageSize = (Math.trunc(ctx.getState().getRecord(recordId).many[fieldId]?.records?.length / pageSize) + 1) * 10;
    }

    const records$ = this.recordService.retrieveCardsRecords(qp, ['originalCheckpointsets', 'value'], pageSize, cursor, (record) => {
      return _.filter(_.flatten(_.map(mirrorField.entity.blocks, (b) => b.fields)), f => f.isExpanded).map(f => f.id).join(',');
    });

    return records$.pipe(
      delay(0),
      mergeMap((response: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();

        const records = response.data
        const count = response.count;

        mutateState(ctx, draft => {
          const diff = count - draft.getRecord(recordId).many[fieldId].count;
          const groupby = draft.getRecord(recordId).filters[fieldId]['groupby'];
          draft.getRecord(recordId).many[fieldId].count = count;
          if (ready) {
            const clonedRecords = [...records];
            clonedRecords.splice(draft.getRecord(recordId).many[fieldId].records.length + diff);
            draft.getRecord(recordId).many[fieldId].records = !groupby ? clonedRecords : groupRecords(mirrorField.entity, clonedRecords, qp);
          } else {
            if (draft.getRecord(recordId).many[fieldId].records && cursor !== -1) {
              const distinctRecords = _.uniqBy(
                _.concat(draft.getRecord(recordId).many[fieldId].records, records),
                'id'
              );
              draft.getRecord(recordId).many[fieldId].records = !groupby ? distinctRecords : groupRecords(mirrorField.entity, distinctRecords, qp);
            } else {
              draft.getRecord(recordId).many[fieldId].records = !groupby ? records : groupRecords(mirrorField.entity, records, qp);
            }
          }
          draft.getRecord(recordId).many[fieldId].loading = false;
        });
        return of(records);
      })
    );
  }

  private retrieveTimelineRecords(recordId: number, ctx: any, cursor: number, ready: boolean = false, field?: any) {
    const fieldId = field.id;
    const mirrorField = field.mirrorFields[0];
    const filters = ctx.getState().getRecord(recordId).filters[fieldId];
    const urlTree = this.router.parseUrl(this.router.url);
    const primary = urlTree.root.children['primary'];
    const auxiliary = urlTree.root.children['modal'];
    let queryParams = locationToQueryParams(this.location.path());

    if (primary && auxiliary) {
      queryParams = queryParams['timelineConfig'] ? {"timelineConfig": queryParams['timelineConfig']} : {};
    }

    const qp = {
      entity: mirrorField.entity.id,
      origin_field: mirrorField.name,
      [mirrorField.name]: recordId,
      ...filters,
      ...queryParams,
      mode: 'timeline'
    };

    let pageSize = computePageSize(12, 75);
    if (ready) {
      const currentLength = ctx.getState().getRecord(recordId).many[fieldId]?.timelineRecords?.length || 0;
      pageSize = (Math.trunc(currentLength / pageSize) + 1) * 8;
    }

    return this.recordService.retrieveTimelineRecords(qp, ['value'], pageSize, cursor, () => {
      return mirrorField.entity.ownerField + ',';
    }).pipe(
      mergeMap((records: any) => {
        if (!ctx.getState().isRecord(recordId)) return of();

        const count = records.count;
        const unscheduledCount = records.unscheduledCount;

        mutateState(ctx, draft => {
          const diff = count - draft.getRecord(recordId).many[fieldId].timelineCount;
          const groupby = draft.getRecord(recordId).filters[fieldId]['groupby'];

          draft.getRecord(recordId).many[fieldId].timelineCount = count;
          draft.getRecord(recordId).many[fieldId].unscheduledCount = unscheduledCount;

          if (ready) {
            records.data.splice(draft.getRecord(recordId).many[fieldId].timelineRecords.length + diff);
            draft.getRecord(recordId).many[fieldId].timelineRecords = !groupby
              ? records.data
              : groupRecords(mirrorField.entity, records.data, qp);
          } else {
            if (draft.getRecord(recordId).many[fieldId].timelineRecords && cursor !== -1) {
              const distinctRecords = _.uniqBy(
                _.concat(draft.getRecord(recordId).many[fieldId].timelineRecords, records.data),
                'id'
              );
              draft.getRecord(recordId).many[fieldId].timelineRecords = !groupby
                ? distinctRecords
                : groupRecords(mirrorField.entity, distinctRecords, qp);
            } else {
              draft.getRecord(recordId).many[fieldId].timelineRecords = !groupby
                ? records.data
                : groupRecords(mirrorField.entity, records.data, qp);
            }
          }
          draft.getRecord(recordId).many[fieldId].loadingTimeline = false;
          draft.getRecord(recordId).many[fieldId].nextCursor = records.nextCursor;
        });

        return of([]);
      })
    );
  }

  private loadTabData(actions$, effect: (res: any) => void, immediate = false, zerolength = () => {}) {
    const operator = immediate ? forkJoin : concat;
    return mergeMap((observables$: any) => (observables$.length ? operator(...observables$).pipe(
      tap((res: any) => {
        effect(res);
      }),
      takeUntil(actions$.pipe(ofAction(RecordPage.DestroyRecord)))
    ) : of([]).pipe(tap(() => {
      zerolength()
    }))));
  }

  private getBlocksElements(recordId, ctx) {
    const tab = ctx.getState().getRecord(recordId).tab;
    const activeElements = [];
    for (const block of ctx.getState().getRecord(recordId).entity.blocks.filter(b => !b.isTabbed && !b.isSider)) {
      const elems = _.sortBy(_.filter(_.concat(block.fields, block.extensions, block.widgets),
        e => extensions.includes(e.type) || fieldMany.includes(e.type) || widgets.includes(e.type)), el => el.position);
      for (const elem of elems) {
        activeElements.push(elem);
      }
    }
    const activeTabbedBlock = this.getActiveTabbedBlock(recordId, ctx, tab);
    if (activeTabbedBlock) {
      if (activeTabbedBlock.title !== tab) {
        mutateState(ctx, draft => {
          draft.getRecord(recordId).tab = activeTabbedBlock.title;
        });
      }
      const elems = _.sortBy(_.filter(_.concat(activeTabbedBlock.fields, activeTabbedBlock.extensions, activeTabbedBlock.widgets),
        e => extensions.includes(e.type) || fieldMany.includes(e.type) || widgets.includes(e.type)), el => el.potision);
      for (const elem of elems) {
        activeElements.push(elem);
      }
    }
    const selectedTabbedBlocks = _.filter(this.getSelectedTabbedBlocks(ctx, recordId), b => b.title !== activeTabbedBlock.title);
    _.forEach(selectedTabbedBlocks, selectedBlock => {
      const elems = _.sortBy(
        _.filter(
          _.concat(selectedBlock.fields, selectedBlock.extensions, selectedBlock.widgets),
          e => extensions.includes(e.type) || fieldMany.includes(e.type) || widgets.includes(e.type)
        ),
        el => el.potision
      );
      for (const elem of elems) {
        activeElements.push(elem);
      }
    });

    return activeElements;
  }

  private getActiveTabbedBlock(recordId, ctx, tab) {
    const tabbedBlocks = ctx.getState().getRecord(recordId).entity.blocks.filter(b => b.isTabbed);
    if (tabbedBlocks.length) {
      const activeTabbedBlock = _.head(_.filter(tabbedBlocks, b => b.title === tab));
      return tab && activeTabbedBlock ? activeTabbedBlock : _.head(_.filter(tabbedBlocks, b => !b.block));
    }
  }

  private setSelectedTabbedBlocks(ctx, recordId, tab) {
    const tabbedBlocks = _.filter(
      ctx.getState().getRecord(recordId).entity.blocks,
      b => b.isTabbed && _.filter(ctx.getState().getRecord(recordId).record.blocks, activeBlock => activeBlock.id === b.id)
    )
    const activeTabBlock = _.head(_.filter(tabbedBlocks, b => b.title === tab && b.isActive));
    if (activeTabBlock) {
      const activeTabParentBlockId = activeTabBlock.block?.id ? activeTabBlock.block?.id : 0;
      mutateState(ctx, draft => {
        draft.getRecord(recordId).selectedTabs[activeTabParentBlockId] = tab
      });
    }

    let groupedTabbedBlocks = _.groupBy(_.orderBy(_.filter(tabbedBlocks, b => b.block?.id !== activeTabBlock.block?.id), 'position'), 'block.id');
    _.forEach(groupedTabbedBlocks, (blocks, group) => {
      const parentBlockId = group == 'undefined' ? 0 : group;

      if (!ctx.getState().getRecord(recordId).selectedTabs[parentBlockId]) {
        let selectedBlock = _.head(blocks);
        mutateState(ctx, draft => {
          draft.getRecord(recordId).selectedTabs[parentBlockId] = selectedBlock?.title
        });
      }
    })
  }

  private getSelectedTabbedBlocks(ctx, recordId) {
    return _.filter(
      ctx.getState().getRecord(recordId).entity.blocks,
      b => _.includes(_.values(ctx.getState().getRecord(recordId).selectedTabs), b.title)
    )
  }

  private static loadPrevState(recordId, ctx, record) {
    if (ctx.getState().getRecord(recordId).tab) {
      return {
        ...record,
        originalCheckpointsets: ctx.getState().getRecord(recordId).record.originalCheckpointsets,
      };
    }
    return record;
  }

}
